<template>
  <base-layout
    :page-title="loadedMemory ? loadedMemory.title : 'Loading....'"
    page-defaul-back-link="/home"
  >
    <ion-card v-if="!loadedMemory">
      <ion-card-header>
        <ion-card-title color="warning">Not find</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        Could not find a memory for the given id.
      </ion-card-content>
    </ion-card>
    <memory-overview
      v-else
      :title="loadedMemory.title"
      :image="loadedMemory.image"
      :description="loadedMemory.description"
    ></memory-overview>
  </base-layout>
</template>

<script>
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
} from "@ionic/vue";
import MemoryOverview from "../components/memories/MemoryOverview.vue";
export default {
  name: "memory-detail",
  components: {
    MemoryOverview,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
  },
  data() {
    return {
      memoryId: this.$route.params.id,
    };
  },
  computed: {
    loadedMemory() {
      return this.$store.getters.memory(this.memoryId);
    },
  },
  watch: {
    $route(currentRoute) {
      this.memoryId = currentRoute.params.id;
    },
  },
};
</script>
<style scoped>
ion-card {
  position: relative;
  left: 0;
  right: 0;
  top: 30%;
  transform: translateY(-50%);
}
</style>
