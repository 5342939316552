<template>
  <ion-card>
      <ion-img :src="image" alt="title"></ion-img>
      <ion-card-header>
        <ion-card-title color="dark">{{ title }}</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        {{ description }}
      </ion-card-content>
    </ion-card>
</template>
<script>
import { IonImg, IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent } from '@ionic/vue';
export default {
    name: 'memory-overview',
    props:['title', 'image', 'description'],
    components:{
        IonImg,
        IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    }

}
</script>
